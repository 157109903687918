import React from 'react';

import Layout from '@src/layouts';
import { ErrorComponent } from '@pages-impl/404/ErrorComponent';

export default function NotFoundPage(props) {
  return (
    <Layout {...props} contactFormType={false}>
      <ErrorComponent />
    </Layout>
  );
}
